
const databits = [
  {
    title: '¿Donde está mi pedido?',
    content:      
      'Una vez confirmado su pedido, nuestros centros de distribución lo procesarán en un lapso de tiempo de 24h-48h laborables en Quito, Guayaquil y Cuenca, y 48h-72h laborables en el resto del país. Si cree que su pedido ya debería haber legado, o desea rastrear su pedido, puede realizarlo contactando nuestro call center.',
    anchor: 'donde-esta-mi-pedido'  
  },
  {
    title: '¿Que debo hacer si tengo algún requisito especial para la entrega de mi pedido?',
    content:
      'Si tiene algún requerimiento especial para la entrega de su pedido, deberá comunicarla con anticipación al momento de realizar su pedido o contactando nuestro call center.',
    anchor: ''
     
  },
  {
    title: 'Quiero realizar una devolución, ¿ como debo proceder?',
    content:
      'A partir de la recepción de su pedido, usted tiene 72h para comunicar su inconformidad y solicitar la devolución del producto. Las devoluciones están sujetas a nuestra política de devolución. Le recomendamos al reibir su pedido revisarlo integramente y no recibir algún producto si evidencia algún desperfecto.',
    anchor: '' 
  }
  

];
export default databits;
